// import React from 'react';
import { init } from './static/smoothScroll.js';
import "./static/fonts/fonts.css"


import AOS from 'aos';
import 'aos/dist/aos.css';

export const onInitialClientRender = () => {
  AOS.init();
};


export const onClientEntry = () => {
    init();
    
};